/* eslint-disable */
export default {
  state: {},
  mutations: {},
  actions: {
    getSensorStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/locations/" + payload + "/latest-data/variables")
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getSensorData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/locations/" + payload.locationId + "/data/variables/" + payload.variableId, { params: payload.params })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getEnvironmentData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/locations/" + payload.locationId + "/data/environments/" + payload.environmentId + "/variables/" + payload.variableId, { params: payload.params })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getApplicationData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/locations/" + payload.locationId + "/data/appliances/" + payload.applianceId + "/variables/" + payload.variableId, { params: payload.params })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getReportData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get(payload.url, { params: payload.data })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getLiveData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get(payload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    // new
    getTotalPowerConsumption({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/locations/" + payload.locationId + "/latest-data/appliances/" + payload.applianceId + "/power-consumption")
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getTotalPowerConsumptionData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/locations/" + payload.locationId + "/data/appliances/" + payload.applianceId + "/power-consumption", { params: payload.params })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getAppliances({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/locations/" + payload + "/appliances")
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getDataAppliance({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/locations/" + payload.locationId + "/latest-data/appliances/" + payload.applianceId + "/variables")
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  },
  getters: {}
};
