<template>
  <div class="header">
    <div class="header-content">
      <nav class="navbar navbar-expand">
        <div class="collapse navbar-collapse justify-content-between">
          <div class="header-left">
            <div class="dashboard_bar">
              {{ pageTitle }}
            </div>
            <!-- <div class="nav-item d-flex align-items-center">
              <div class="input-group search-area">
                <input type="text" class="form-control" placeholder="">
                <span class="input-group-text"><a href="javascript:void(0)"><i
                      class="flaticon-381-search-2"></i></a></span>
              </div>
              <div class="plus-icon">
                <a href="javascript:void(0);"><i class="fas fa-plus"></i></a>
              </div>
            </div> -->
          </div>
          <ul class="navbar-nav header-right">
            <!-- <li class="nav-item dropdown notification_dropdown">
              <a class="nav-link" href="javascript:void(0);" role="button" data-bs-toggle="dropdown">
                <i class="far fa-bell header-icon"></i>
                <span class="badge light text-white bg-primary rounded-circle">4</span>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <div id="DZ_W_Notification1" class="widget-media dlab-scroll p-3" style="height:380px;">
                  <ul class="timeline">
                    <li>
                      <div class="timeline-panel">
                        <div class="media me-2">
                          <img alt="image" width="50" src="images/avatar/1.jpg">
                        </div>
                        <div class="media-body">
                          <h6 class="mb-1">Dr sultads Send you Photo</h6>
                          <small class="d-block">29 July 2020 - 02:26 PM</small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="timeline-panel">
                        <div class="media me-2 media-info">
                          KG
                        </div>
                        <div class="media-body">
                          <h6 class="mb-1">Resport created successfully</h6>
                          <small class="d-block">29 July 2020 - 02:26 PM</small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="timeline-panel">
                        <div class="media me-2 media-success">
                          <i class="fa fa-home"></i>
                        </div>
                        <div class="media-body">
                          <h6 class="mb-1">Reminder : Treatment Time!</h6>
                          <small class="d-block">29 July 2020 - 02:26 PM</small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="timeline-panel">
                        <div class="media me-2">
                          <img alt="image" width="50" src="images/avatar/1.jpg">
                        </div>
                        <div class="media-body">
                          <h6 class="mb-1">Dr sultads Send you Photo</h6>
                          <small class="d-block">29 July 2020 - 02:26 PM</small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="timeline-panel">
                        <div class="media me-2 media-danger">
                          KG
                        </div>
                        <div class="media-body">
                          <h6 class="mb-1">Resport created successfully</h6>
                          <small class="d-block">29 July 2020 - 02:26 PM</small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="timeline-panel">
                        <div class="media me-2 media-primary">
                          <i class="fa fa-home"></i>
                        </div>
                        <div class="media-body">
                          <h6 class="mb-1">Reminder : Treatment Time!</h6>
                          <small class="d-block">29 July 2020 - 02:26 PM</small>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <a class="all-notification" href="javascript:void(0);">See all notifications <i
                    class="ti-arrow-end"></i></a>
              </div>
            </li> -->
            <!-- <li class="nav-item">
              <a class="nav-link d-flex mt-n1" href="javascript:void(0);" data-bs-toggle="tooltip" title="Download App" @click="$router.push('/download-apk')">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
              width="25" height="25"
              viewBox="0 0 50 50"
              style=" fill: #464a53"><path d="M 16.28125 0.03125 C 16.152344 0.0546875 16.019531 0.078125 15.90625 0.15625 C 15.449219 0.464844 15.347656 1.105469 15.65625 1.5625 L 17.8125 4.78125 C 14.480469 6.546875 11.996094 9.480469 11.1875 13 L 38.8125 13 C 38.003906 9.480469 35.519531 6.546875 32.1875 4.78125 L 34.34375 1.5625 C 34.652344 1.105469 34.550781 0.464844 34.09375 0.15625 C 33.632813 -0.152344 32.996094 -0.0195313 32.6875 0.4375 L 30.3125 3.9375 C 28.664063 3.335938 26.875 3 25 3 C 23.125 3 21.335938 3.335938 19.6875 3.9375 L 17.3125 0.4375 C 17.082031 0.09375 16.664063 -0.0429688 16.28125 0.03125 Z M 19.5 8 C 20.328125 8 21 8.671875 21 9.5 C 21 10.332031 20.328125 11 19.5 11 C 18.667969 11 18 10.332031 18 9.5 C 18 8.671875 18.667969 8 19.5 8 Z M 30.5 8 C 31.332031 8 32 8.671875 32 9.5 C 32 10.332031 31.332031 11 30.5 11 C 29.671875 11 29 10.332031 29 9.5 C 29 8.671875 29.671875 8 30.5 8 Z M 8 15 C 6.34375 15 5 16.34375 5 18 L 5 32 C 5 33.65625 6.34375 35 8 35 C 8.351563 35 8.6875 34.925781 9 34.8125 L 9 15.1875 C 8.6875 15.074219 8.351563 15 8 15 Z M 11 15 L 11 37 C 11 38.652344 12.347656 40 14 40 L 36 40 C 37.652344 40 39 38.652344 39 37 L 39 15 Z M 42 15 C 41.648438 15 41.3125 15.074219 41 15.1875 L 41 34.8125 C 41.3125 34.921875 41.648438 35 42 35 C 43.65625 35 45 33.65625 45 32 L 45 18 C 45 16.34375 43.65625 15 42 15 Z M 15 42 L 15 46 C 15 48.207031 16.792969 50 19 50 C 21.207031 50 23 48.207031 23 46 L 23 42 Z M 27 42 L 27 46 C 27 48.207031 28.792969 50 31 50 C 33.207031 50 35 48.207031 35 46 L 35 42 Z"></path></svg>
                <strong class="ms-1">App</strong>
              </a>
            </li> -->
            <li class="nav-item">
              <a v-if="themeVer == 'dark'" class="nav-link" href="javascript:void(0);" @click="$emit('themeVer', 'light')">
                <i class="far fa-moon header-icon"></i>
              </a>
              <a v-else class="nav-link" href="javascript:void(0);" @click="$emit('themeVer', 'dark')">
                <i class="far fa-sun header-icon"></i>
              </a>
            </li>
            <li class="nav-item dropdown header-profile">
              <a class="nav-link" href="javascript:void(0);" role="button" data-bs-toggle="dropdown">
                <img v-if="userData" :src="userData.avatar" @error="$event.target.src = require('@/assets/default-avatar.png')" width="20" alt="" />
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <router-link to="/profile" class="dropdown-item ai-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="18" height="18"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                  <span class="ms-2">Profile </span>
                </router-link>
                <a @click="logout()" class="dropdown-item ai-icon btn-logout">
                  <svg xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                  </svg>
                  <span class="ms-2">Logout </span>
                </a>
              </div>
            </li>
            <li class="nav-item">
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  name: 'base-header',
  props: ['pageTitle', 'themeVer'],
  computed: {
    userData () {
      return this.$store.getters['user/data']
    },
    theme () {
      return this.$store.getters['config/theme']
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('user/logout')
    }
  },
  watch: {
  }
}
</script>
<style scoped>
.btn-logout {
  cursor: pointer;
}

.header-profile .dropdown-menu a:hover {
  color: #393939;
}

.header-icon {
  font-size: 1.7rem !important;
}

.header-right .notification_dropdown .nav-link {
  color: #464a53;
}
</style>
