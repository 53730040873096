<template>
  <PageTemplate title="Search Report">
    <template slot="content">
      <div :class="isMobileView ? '' : ''">
        <div class="flex-grow-1 mb-2"  style="min-height: 800px !important">
          <div class="card mb-1" ref="detailsWrapper">
            <div class="card-header">
              <div class="row flex-grow-1 gap-0">
                <div class="col-sm-6 col-md-3">
                  <select class="form-select form-control mb-2" v-model="search.location_id">
                    <option :selected="search.location_id == null ? true : false" :value="null">Choose Location</option>
                    <option v-for="list in locations" :value="list.id" :key="list.id">{{ list.name }}</option>
                  </select>
                </div>
                <div class="col-sm-6 col-md-2">
                  <select class="form-select form-control mb-2" v-model="search.type">
                    <option :selected="search.type == null ? true : false" :value="null">Choose type</option>
                    <option value="dailysummary">Daily Summary</option>
                    <option value="environment">Environment</option>
                    <option value="appliance">Appliance</option>
                    <option value="variable">System</option>
                  </select>
                </div>
                <div class="col-sm-6 col-md-3" v-if="search.type === 'dailysummary'">
                  <div class="input-group mb-2"  style="flex-wrap: nowrap;">
                    <span class="input-group-text "><i class="bi bi-calendar"></i></span>
                    <date-picker v-model="search.yearMonth" type="month" valueType="format" style="width: 100%;"  placeholder="Year/Month" format="YYYY-MM"></date-picker>
                    <span
                      @click="search.yearMonth = null"
                      class="input-group-text bg-white cursor-pointer"
                      style="border: 1px solid #ccc">
                      <span class="text-danger" ><i class="bi bi-arrow-clockwise" style="font-size: 16px"></i></span>
                    </span>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3" v-else>
                  <div class="input-group mb-2" style="flex-wrap: nowrap;">
                    <span class="input-group-text "><i class="bi bi-calendar-range"></i></span>
                    <date-range-picker v-model="search.dateRange" opens="right" :showWeekNumbers="false"
                      class="d-block flex-grow-1" :locale-data="locale">
                      <!--Optional scope for the input displaying the dates -->
                      <template v-slot:input="picker">
                        <span v-if="picker.startDate && picker.endDate">{{ picker.startDate | date }} - {{ picker.endDate
                          | date }}</span>
                        <span v-else>Select Date Range</span>
                      </template>
                    </date-range-picker>
                    <span @click="search.dateRange.startDate = null, search.dateRange.endDate = null"
                      class="input-group-text bg-white cursor-pointer" style="border: 1px solid #ccc">
                      <span class="text-danger"><i class="bi bi-arrow-clockwise" style="font-size: 16px"></i></span>
                    </span>
                  </div>
                </div>
                <div class="col-sm-6 col-md-2" v-if="search.type === 'appliance'">
                  <select class="form-select form-control mb-2" v-model="appliance" :disabled="isLoading">
                    <option :selected="search.type == null ? true : false" :value="null">Choose appliance</option>
                    <option v-for="(item, i) in appliances" :value="item.id" :key="i + 'appl'">{{ item.name }}</option>
                  </select>
                </div>
                <!-- <div class="col-sm-6 col-md-2" v-if="search.type === 'environment'">
                  <select class="form-select form-control mb-2" v-model="environment" :disabled="isLoading">
                    <option :selected="search.type == null ? true : false" :value="null">Choose environment</option>
                    <option v-for="(item, i) in environments" :value="item.id" :key="i + 'appl'">{{ item.name }}</option>
                  </select>
                </div> -->
                <!-- <div class="col-sm-6 col-md-2" v-if="search.type === 'dailysummary' && environment">
                  <select class="form-select form-control mb-2" v-model="envVariable" :disabled="isLoading">
                    <option :selected="search.type == null ? true : false" :value="null">Choose Variable</option>
                    <option v-for="(item, i) in envVariables" :value="item.id" :key="i + 'appl'" >{{item.name}}</option>
                  </select>
                </div> -->
                <div class="col-sm-12 col-md-4 text-center text-md-start" v-if="search.type">
                  <button class="btn btn-primary me-2" @click="getReport"
                    :disabled="isLoading || (search.type !== 'dailysummary' ? !search.dateRange.startDate : !search.yearMonth || !search.location_id || (search.type === 'appliance' ? !appliance : false))">Get
                    Report</button>
                  <vue-excel-xlsx
                    v-if="dataTable.length && (search.type !== 'dailysummary' && search.type !== 'environment')"
                    :data="dataTable" :columns="tableHeader"
                    :file-name="'Report_' + activereportLocation + '_' + moment(dataInfo.start_date).format('DD-MM-YYYYY') + '-' + moment(dataInfo.end_date).format('DD-MM-YYYYY')"
                    :file-type="'xlsx'" :sheet-name="'report'" class="btn light btn-primary">
                    Excel
                  </vue-excel-xlsx>
                  <template v-else-if="dataTable.length">
                    <button @click="exportToExcel" class="btn light btn-primary me-2" :disabled="isLoading">Excel</button>
                    <button @click="downloadPDF" class="btn light btn-primary" v-if="reportType === 'dailysummary'" :disabled="isLoading">PDF</button>
                  </template>
                </div>
              </div>
              <!-- <div class="mb-3">
                <label  class="form-label font-w600">Minutes Gap</label>
                <select class="form-select form-control" v-model="search.minute_gap">
                  <option :selected="search.minute_gap == null ? true : false" :value="null">Choose...</option>
                  <option v-for="list in intervals" :value="list.value" :key="list.value">{{list.text}}</option>
                </select>
              </div> -->
            </div>
            <div class="card-body">
              <vue-good-table styleClass="vgt-table bordered" ref="myTable"
                v-if="dataTable.length && reportType !== 'dailysummary' && reportType !== 'environment'"
                :totalRows="dataTable.length" :columns="tableHeader" :rows="dataTable" :search-options="{
                  enabled: false,
                  placeholder: 'Search Location Name'
                }"
                :sort-options="{ enabled: false }" :pagination-options="{
                  enabled: true,
                  perPage: 5,
                  perPageDropdownEnabled: false,
                }" :theme="theme.themeVersion === 'dark' ? 'nocturnal' : null">
                <!-- <template slot="table-row" slot-scope="props" v-if="dataTable.length">
                  <span v-if="props.column.field == 'via'">
                    <span style="font-weight: bold" :class="props.row.via == 'RTU' ? 'text-success' : 'text-primary'" >{{props.row.via}}</span>
                  </span>
                  <span v-else-if="props.column.field == 'timestamp'">
                    {{ moment(props.row.timestamp).format('DD/MM/YYYY h:hh a')}}
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template> -->
              </vue-good-table>
              <div v-else-if="dataTable.length">
                <div class="vgt-responsive" >
                  <table class="vgt-table bordered">
                    <thead>
                      <tr>
                        <th v-for="(main, i) in tableHeader" :key="'mainheader' + i"
                          :rowspan="i !== 0 ? 1 : reportType == 'dailysummary' ? 3 : 2"
                          :colspan="i == 0 ? 1 : reportType == 'dailysummary' ? 6 : 2"
                          :class="i !== 0 ? 'text-center' : ''">
                          {{ main.name }}
                        </th>
                      </tr>
                      <tr>
                        <template v-for="(main, i) in tableHeader">
                          <th v-for="(sub, ix) in main.variables" :key="i + 'subheaderRowOne' + ix" class="text-center"
                            :colspan="reportType == 'dailysummary' ? 3 : 1">
                            {{ reportType !== 'dailysummary' ? sub : sub.name }}
                          </th>
                        </template>
                      </tr>
                      <tr v-if="reportType == 'dailysummary'">
                        <template v-for="(main, x) in tableHeader">
                          <template v-for="(sub, i) in main.variables">
                            <th v-for="(type, ix) in sub.columns" :key="x + 'id' + i + 'subheaderRowTwo' + ix"
                              class="text-center">
                              {{ type }}
                            </th>
                          </template>
                        </template>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(value, i) in dataTablePerPage" :key="'row-' + i">
                        <td v-for="v in ((tableHeader.length - 1) * (reportType == 'dailysummary' ? 6 : 2)) + 1"
                          :key="'col-' + v">{{ value[v - 1] }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="vgt-wrap__footer vgt-clearfix">
                  <div class="footer__navigation vgt-pull-right">
                    <div class="footer__navigation__page-info">
                      <div >
                        {{((page * perPage) + 1) + ' - ' + (dataTable.length > ((page * perPage) + perPage) ? ((page * perPage) + perPage) : dataTable.length ) + ' of ' + dataTable.length}}
                      </div>
                    </div>
                    <button type="button"  class="footer__navigation__page-btn" :disabled="page === 0" @click="page = page - 1">
                      <span aria-hidden="true" class="chevron left"></span>
                      <span>Previous</span>
                    </button>
                    <button type="button"
                      @click="page = page + 1"
                      :disabled="(dataTable.length > ((page * perPage) + perPage) ? ((page * perPage) + perPage) : dataTable.length) === dataTable.length"
                      class="footer__navigation__page-btn">
                      <span>Next</span>
                      <span aria-hidden="true"
                        class="chevron right"></span>
                    </button>
                  </div>
                </div>
              </div>
              <div v-else class="text-center align-items-center justify-content-center d-flex m-auto"
                style="max-width: 300px; height: 100%">
                <em class="mb-5">No record found.</em>
              </div>
              <VueHtml2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
                @beforeDownload="isLoading = false, generatingPDF = false" :paginate-elements-by-height="1200" :pdf-quality="1"
                :manual-pagination="true" pdf-content-width="100%" ref="html2Pdf" :html-to-pdf-options="{
                  margin: 0.5,
                  jsPDF: { unit: 'cm', format: 'a4', orientation: 'landscape' },
                  filename: 'Environment Monitoring and Control System Report (' + reportType + ')'
                }">
                <section slot="pdf-content" style="padding: 1.5cm, 1.5cm, 1.5cm, 1.5cm">
                  <div style="font-size: 16px;">Environment Monitoring and Control System Report</div>
                  <template v-if="getReportLocation">
                    <div style="font-size: 12px;"><strong>Location: </strong> {{ getReportLocation.name }}</div>
                    <div style="font-size: 12px;" class="pb-1"><strong>Coordinate: </strong> lat {{ getReportLocation.lat
                    }}, lng {{ getReportLocation.lng }}</div>
                  </template>
                  <!-- PDF Content Here -->
                  <div style="width: 27cm; page-break-after: always; margin: auto">
                    <div v-for="(item, i) in chartData" :key="i" class="mt-3">
                      <h4 class="text-center">{{ item.title }}</h4>
                      <ChartLine height="200" style="width: 26.5cm; height: 300px;"  :data="item.data" :labels="chartLabels"
                      :options="options"  />
                    </div>
                  </div>
                  <table class="vgt-table bordered"
                    style="page-break-inside: auto !important; page-break-before: avoid;">
                    <thead>
                      <tr style="break-after: auto;">
                        <th v-for="(main, i) in tableHeader" :key="'mainheader' + i"
                          style="font-size: 12px; padding: 5px;"
                          :rowspan="i !== 0 ? 1 : reportType == 'dailysummary' ? 3 : 2"
                          :colspan="i == 0 ? 1 : reportType == 'dailysummary' ? 6 : 2"
                          :class="i !== 0 ? 'text-center' : ''">
                          {{ main.name }}
                        </th>
                      </tr>
                      <tr style="break-after: auto;">
                        <template v-for="(main, i) in tableHeader">
                          <th v-for="(sub, ix) in main.variables" :key="i + 'subheaderRowOne' + ix" class="text-center" style="font-size: 10px; padding: 5px 3px;"
                            :colspan="reportType == 'dailysummary' ? 3 : 1">
                            {{ reportType !== 'dailysummary' ? sub : sub.name }}
                          </th>
                        </template>
                      </tr>
                      <tr style="break-after: auto;" v-if="reportType == 'dailysummary'">
                        <template v-for="(main, x) in tableHeader">
                          <template v-for="(sub, i) in main.variables">
                            <th v-for="(type, ix) in sub.columns" :key="x + 'id' + i + 'subheaderRowTwo' + ix" style="font-size: 10px; padding: 5px 3px;"
                              class="text-center">
                              {{ type }}
                            </th>
                          </template>
                        </template>
                      </tr>
                    </thead>
                    <tbody style="break-inside: auto !important;">
                      <tr
                        style="break-after: auto !important; break-before: auto !important; break-inside: avoid !important;"
                        v-for="(value, i) in dataTable" :key="'row-' + i">
                        <td v-for="v in ((tableHeader.length - 1) * (reportType == 'dailysummary' ? 6 : 2)) + 1"
                          :key="'col-' + v" style="font-size: 10px; padding: 5px 3px;">{{ value[v - 1] }}</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </VueHtml2pdf>
              <div class="cust-loading" v-if="generatingPDF">
                <div class="text-center" style="font-size: 18px;">
                  <i class="fas fa-spinner fa-spin fa-2xl" ></i>
                  <div>Generating PDF</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </PageTemplate>
</template>
<script>
import PageTemplate from '@/components/Templates/PageTemplate'
import DateRangePicker from 'vue2-daterange-picker'
import ChartLine from '@/components/Templates/ChartLineReport'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import * as XLSX from 'xlsx-js-style'
import VueHtml2pdf from 'vue-html2pdf'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
export default {
  name: 'Reports',
  components: {
    PageTemplate,
    DateRangePicker,
    VueGoodTable,
    VueHtml2pdf,
    DatePicker,
    ChartLine
  },
  data () {
    return {
      isLoading: false,
      generatingPDF: false,
      locale: {
        direction: 'ltr', // direction of text
        format: 'dd-mm-yyyy', // fomart of the dates displayed
        separator: ' - ', // separator between the two ranges
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        /* daysOfWeek: moment.weekdaysMin(), // array of days - see moment documenations for details
        monthNames: moment.monthsShort(), // array of month names - see moment documenations for details */
        firstDay: 1 // ISO first day of week - see moment documenations for details
      },
      search: {
        yearMonth: null,
        dateRange: {
          startDate: null,
          endDate: null
        },
        location_id: null,
        minute_gap: null,
        type: null
      },
      appliance: '',
      appliances: [],
      environment: '',
      environments: [],
      envVariables: [],
      envVariable: null,
      locations: [],
      intervals: [
        { text: '5 minutes', value: 5 },
        { text: '15 minutes', value: 15 },
        { text: '1 hour', value: 60 }
      ],
      reportType: '',
      tableHeader: [],
      dataTable: [],
      dataInfo: null,
      activereportLocation: '',
      pdfcode: '',

      // chart
      chartData: [],
      chartLabels: [],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index'
        },
        plugins: {
          legend: {
            display: true
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Value'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Time'
            }
          }
        },
        elements: {
          line: {
            tension: 0
          }
        }
      },

      // custom pagination
      page: 0,
      perPage: 10
    }
  },
  filters: {
    date (value) {
      if (!value) { return '' }
      // const options = { year: 'numeric', month: 'short', day: 'numeric' }
      return moment(value).format('MM/DD/YYYY')
      // return Intl.DateTimeFormat('en-US', options).format(value)
    }
  },
  created () {
    this.$emit('pageTitle', 'Report')
    this.init()
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    },
    isMobileView () {
      return this.$store.getters['global/mobileView']
    },
    getSelectedLocation () {
      return this.$store.getters['locations/selectedLocation']
    },
    getReportLocation () {
      return this.locations.find(x => x.id === this.search.location_id)
    },
    dataTablePerPage () {
      const startIndex = this.page * this.perPage
      const endIndex = startIndex + this.perPage
      return this.dataTable.slice(startIndex, endIndex)
    }
  },
  watch: {
    'search.location_id': {
      deep: true,
      handler (val) {
        if (val) {
          if (val === 'variable') {
            this.appliance = null
            this.environment = null
            this.envVariable = null
          } else if (val === 'applicance') this.environment = null
          else if (val === 'environment') this.appliance = null
          this.getAppliance()
          this.getEnvironment()
        }
      }
    },
    environment (val) {
      if (val) {
        this.isLoading = true
        this.envVariables = []
        this.getEnvVariables(val).then(e => {
          this.envVariables = e
          this.isLoading = false
        })
      }
    }
  },
  methods: {
    getAppliance () {
      this.isLoading = true
      return new Promise((resolve, reject) => {
        this.$store.dispatch('getAppliances', this.search.location_id).then(res => {
          this.appliances = res.data
          this.appliance = null
          this.isLoading = false
          resolve(this.appliances)
        }).catch(err => {
          reject(err.response.data.error)
          this.$toast.error(err.response.data.error)
          this.isLoading = false
        })
      })
    },
    getEnvironment () {
      this.isLoading = true
      return new Promise((resolve, reject) => {
        this.$store.dispatch('getEnvironments', this.search.location_id).then(res => {
          this.environments = res.data
          this.environment = null
          this.isLoading = false
          resolve(this.environments)
        }).catch(err => {
          reject(err.response.data.error)
          this.$toast.error(err.response.data.error)
          this.isLoading = false
        })
      })
    },
    getEnvVariables (id) {
      return new Promise((resolve, reject) => {
        const payload = {
          locationId: this.search.location_id,
          environmentId: id
        }
        this.$store.dispatch('getEnvVariables', payload).then(res => {
          resolve(res.data.variables)
        }).catch(err => {
          reject(err.response.data.error)
          this.$toast.error(err.response.data.error)
        })
      })
    },
    downloadPDF () {
      this.isLoading = true
      this.generatingPDF = true
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf()
      }, 200)
    },
    getReport () {
      this.isLoading = true
      this.reportType = this.search.type
      const payload = {
        data: {}
      }
      if (this.search.type === 'appliance') payload.url = '/locations/' + this.search.location_id + '/reports/appliances/' + this.appliance + '/variables'
      if (this.search.type === 'environment') payload.url = '/locations/' + this.search.location_id + '/reports/environment/data'
      if (this.search.type === 'dailysummary') payload.url = '/locations/' + this.search.location_id + '/reports/environment/daily' // + this.environment + '/variables/' + this.envVariable
      else if (this.search.type === 'variable') payload.url = '/locations/' + this.search.location_id + '/reports/variables'

      if (this.search.type !== 'dailysummary' && (this.search.dateRange.startDate !== null || this.search.dateRange.endDate !== null)) {
        payload.data.start_date = moment(this.search.dateRange.startDate).format('YYYY-MM-DD') + (this.search.type !== 'dailysummary' ? ' 00:00:00' : '')
        payload.data.end_date = moment(this.search.dateRange.endDate).format('YYYY-MM-DD') + (this.search.type !== 'dailysummary' ? ' 23:59:59' : '')
      } else if (this.search.type === 'dailysummary' && this.search.yearMonth !== null) {
        payload.data.year_month = this.search.yearMonth
      }
      this.dataTable = []
      this.tableHeader = []
      this.$store.dispatch('getReportData', payload).then(res => {
        this.isLoading = false
        this.dataTable = res.data.data
        if (this.search.type === 'environment') {
          const newArray = [{
            name: 'Date',
            variables: []
          }]
          this.tableHeader = [...newArray, ...res.data.columns]
        } else this.tableHeader = res.data.columns
        if (this.search.type === 'dailysummary') {
          const colors = ['#1d57b9', '#00693E', '#ED9121', '#6F00FF', '#800020']
          const newDataAvgHumidities = []
          const newDataAvgTemperatures = []
          res.data.graphs.avgHumidities.forEach((e, i) => {
            const newLabel = []
            const newItem = {
              label: e.name,
              borderColor: colors[i],
              backgroundColor: 'rgba(0,0,0,0)',
              data: []
            }
            e.data.forEach((item, ix) => {
              newItem.data.push(item.value)
              if (i === 0) newLabel.push(item.date)
              if (ix + 1 === e.data.length) {
                newDataAvgHumidities.push(newItem)
                // only for 1 time labelfor both chart
                if (i === 0) this.chartLabels = newLabel
              }
            })
            if (newDataAvgHumidities.length === res.data.graphs.avgHumidities.length) {
              this.chartData.push({
                title: 'Average Humidity',
                data: newDataAvgHumidities
              })
            }
          })
          res.data.graphs.avgTemperatures.forEach((e, i) => {
            const newItem = {
              label: e.name,
              borderColor: colors[i],
              backgroundColor: 'rgba(0,0,0,0)',
              data: []
            }
            e.data.forEach((item, ix) => {
              newItem.data.push(item.value)
              if (ix + 1 === e.data.length) {
                newDataAvgTemperatures.push(newItem)
              }
            })
            if (newDataAvgTemperatures.length === res.data.graphs.avgTemperatures.length) {
              this.chartData.push({
                title: 'Average Temperature',
                data: newDataAvgTemperatures
              })
            }
          })
        }
        this.activereportLocation = this.locations.find(x => x.id === this.search.location_id).name
        this.dataInfo = payload
      }).catch(err => {
        this.isLoading = false
        console.log(err)
        this.$toast.error(err.response.data.message)
      })
    },
    init () {
      this.search.location_id = this.getSelectedLocation.id
      this.$store.dispatch('locations/getLocations').then(res => {
        this.locations = res.data
      })
    },
    async exportToExcel () {
      const headerExcel = await this.createHeaderRow(this.tableHeader)
      const wb = XLSX.utils.book_new()

      // Add headers to the worksheet
      const wsData = [...headerExcel.headerTable]
      const ws = XLSX.utils.aoa_to_sheet(wsData.concat(this.dataTable))

      // Specify merged cells for the header rows
      const merges = headerExcel.headerConfig
      // this.addMergedCells(sampleH, 0, 0, merges)

      // Set the merges property for the worksheet
      ws['!merges'] = merges

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

      // Save the workbook as an Excel file
      XLSX.writeFile(wb, 'Environment Monitoring and Control System Report (' + this.reportType + ').xlsx')
    },
    createHeaderRow (data) {
      return new Promise((resolve) => {
        const sensorLength = data.length - 1
        const newMergeConfig = [
          { s: { r: 0, c: 0 }, e: { r: this.reportType === 'dailysummary' ? 2 : 1, c: 0 } }
        ]
        const newArray = [
          [['Date']],
          [[null]]
        ]
        if (this.reportType === 'dailysummary') {
          newArray.push([[null]])
        }
        for (let index = 0; index < sensorLength; index++) {
          // Env
          const dailyROne = [[null], [null], [null], [null], [null]]
          const envRowOne = [[null]]

          newArray[0].push([data[index + 1].name])
          newMergeConfig.push({
            s: { r: 0, c: this.reportType === 'dailysummary' ? (index * 6) + 1 : (index * 2) + 1 },
            e: { r: 0, c: this.reportType === 'dailysummary' ? (index + 1) * 6 : (index + 1) * 2 }
          })
          if (this.reportType === 'dailysummary') {
            newArray[0] = [...newArray[0], ...dailyROne]

            // Sensor
            newArray[1].push(['Humidity'], [null], [null], ['Temperature'], [null], [null])
            newMergeConfig.push({
              s: { r: 1, c: (index * 6) + 1 },
              e: { r: 1, c: ((index + 1) * 6) - 3 }
            })
            newMergeConfig.push({
              s: { r: 1, c: (index * 6) + 4 },
              e: { r: 1, c: (index + 1) * 6 }
            })
          } else {
            newArray[0] = [...newArray[0], ...envRowOne]
            newArray[1].push(['Humidity'], ['Temperature'])
          }

          // variable
          if (this.reportType === 'dailysummary') newArray[2].push(['Min'], ['Max'], ['Average'], ['Min'], ['Max'], ['Average'])

          // resolve when loop end
          if (newArray[0].length === (sensorLength * (this.reportType === 'dailysummary' ? 6 : 2)) + 1) {
            resolve({
              headerTable: newArray,
              headerConfig: newMergeConfig
            })
          }
        }
      })
    }
    /* addHeaderRow (headerHtml) {
      // Access the table's DOM reference
      const table = document.getElementById('vgt-table')

      // Create a new tr element
      const newRow = document.createElement('tr')
      const tHead = table.querySelector('thead')

      // Append the new tr element to the thead
      tHead.insertBefore(newRow, tHead.firstChild)

      newRow.innerHTML = headerHtml

      // You can now add any content or additional cells to the newRow if needed
      // Example: newRow.innerHTML = '<th>New Column</th>';
    } */
  }

}
</script>
<style>
.cust-loading {
    position: fixed;
    top: 0;
    right: 0;
    background: rgb(255 255 255 / 70%);
    height: 100%;
    width: calc(100% - 11.25rem);
    display: flex;
    align-items: center;
    justify-content: center;
}
[data-sidebar-style="compact"] .cust-loading {
  width: calc(100% - 11.25rem);
}
[data-sidebar-style="mini"] .cust-loading {
  width: calc(100% - 6.25rem);
}
</style>
