<template>
  <div style="transition: all ease 0.3s; min-height: 97px" class="d-flex" :style="{ width: (align === 'left' ? '70%' : '100%'), }">
    <div class="chart-wrapper m-auto" style="height: 100%; width: 120px; transition: all ease 0.3s">
      <div class="text-center" style="line-height: 16px; margin-bottom: -25% !important;"><small>{{selected}}</small></div>
      <div class="chart-middle text-center" :style="{ bottom: sensor.hideChart ? '-4px' : ''}">
        <div class="justify-content-center" :class="sensor.hideChart ? '' : 'd-flex'">
          <h3 style="margin-bottom: -8px; margin-right: 2px;" :class="sensor.status === 'Normal' ? 'text-normal' : sensor.status === 'Warning' ? 'text-warning' : sensor.status === 'Alert' ? 'text-danger' : ''"><strong>{{typeof sensor.value !== 'boolean' ? sensor.value : sensor.value === true ? 'ON' : 'OFF'}}</strong></h3>
          <span v-if="sensor.hideChart" style="width: 100%; margin-top: 5px; display: block;">{{sensor.unit}}</span>
        </div>
      </div>
      <div class="" :style="{ opacity: (typeof sensor.value !== 'boolean') ? '1' : '0' } " >
        <Chart
        height="100"
        v-if="!sensor.hideChart"
        :chartData="sensor"  />
      </div>
      <div style="width: 100%; max-width: 240px; margin-top: -20%" class="text-center d-flex  justify-content-between pb-3" :style="{ opacity: (typeof sensor.value !== 'boolean') ? '1' : '0' } " v-if="!sensor.hideChart">
        <div style="width: 20px; position: relative;">
          <span style="position: absolute; top: 0; left: 50%; transform: translateX(-50%); white-space: nowrap">{{sensor.min ? sensor.min : 0}}</span>
        </div>
        <div style="width: 20px; position: relative;">
          <span style="position: absolute; top: 0; left: 50%; transform: translateX(-50%); white-space: nowrap">{{sensor.unit}}</span>
        </div>
        <div style="width: 20px; position: relative;">
          <span style="position: absolute; top: 0; left: 50%; transform: translateX(-50%); white-space: nowrap">{{sensor.max == 0 || sensor.max == null ? 100 : sensor.max}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from '@/components/Templates/Charts'
export default {
  props: ['sensor', 'selected', 'align'],
  components: {
    Chart
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
.chart-wrapper {
  width: 70%;
  position: relative;
}
.chart-middle {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
}
.text-normal {
  color: #0e3d8e

}
</style>
