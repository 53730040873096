import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import Login from '@/views/Login.vue'
import LoginSuccess from '@/views/LoginSuccess.vue'
import Playground from '@/views/Test.vue'
import Error404 from '@/views/Error404.vue'
import Dashboard from '@/views/Dashboard.vue'
import Profile from '@/views/Profile.vue'
import UserList from '@/views/Users/List.vue'
import UserDetail from '@/views/Users/Detail.vue'
import Blank from '@/views/Blank.vue'
import RTU from '@/views/RTU/List.vue'
import RTUDetails from '@/views/RTU/Details.vue'
import Locations from '@/views/Locations/List.vue'
import LocationsDetails from '@/views/Locations/Details.vue'
import Photos from '@/views/Photos/List.vue'
import Gallery from '@/views/Photos/Gallery.vue'
import LogsAlarm from '@/views/Logs/List.vue'
import LogsSummary from '@/views/Logs/Summary.vue'
import System from '@/views/System.vue'
import Sensor from '@/views/Sensor.vue'
import Environments from '@/views/Environments.vue'
import Report from '@/views/Reports.vue'
import DownloadAPK from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/Playground',
    name: 'Playground',
    component: Playground
  },
  {
    path: '/download-apk',
    name: 'Download APK',
    component: DownloadAPK
  },
  {
    path: '/login-success/:token',
    name: 'login-success',
    component: LoginSuccess
  },
  {
    path: '/404',
    name: 'error-404',
    component: Error404
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/settings/users',
    name: 'users-list',
    component: UserList,
    meta: { adminAuth: true }
  },
  {
    path: '/settings/rtu',
    name: 'RTU',
    component: RTU,
    meta: { adminAuth: true }
  },
  {
    path: '/settings/locations/:id',
    name: 'Locations Details',
    component: LocationsDetails,
    meta: { adminAuth: true }
  },
  {
    path: '/settings/locations/create',
    name: 'Create Locations',
    component: LocationsDetails,
    meta: { adminAuth: true }
  },
  {
    path: '/settings/locations',
    name: 'Locations',
    component: Locations,
    meta: { adminAuth: true }
  },
  {
    path: '/settings/rtu/:id',
    name: 'RTU',
    component: RTUDetails,
    meta: { adminAuth: true }
  },
  {
    path: '/settings/rtu/create',
    name: 'Create RTU',
    component: RTUDetails,
    meta: { adminAuth: true }
  },
  {
    path: '/settings/users/:id',
    name: 'users-detail',
    component: UserDetail,
    meta: { adminAuth: true }
  },
  {
    path: '/photos',
    name: 'photos',
    component: Photos,
    meta: { adminAuth: true }
  },
  {
    path: '/photos/gallery/',
    name: 'gallery',
    component: Gallery,
    meta: { adminAuth: false }
  },
  {
    path: '/logs/list',
    name: 'alarm logs',
    component: LogsAlarm,
    meta: { adminAuth: false }
  },
  {
    path: '/logs/summary',
    name: 'alarm logs summary',
    component: LogsSummary,
    meta: { adminAuth: false }
  },
  {
    path: '/system',
    name: 'System',
    component: System,
    meta: { adminAuth: false }
  },
  {
    path: '/environments',
    name: 'Environments',
    component: Environments,
    meta: { adminAuth: false }
  },
  {
    path: '/appliances',
    name: 'Appliances',
    component: Sensor,
    meta: { adminAuth: false }
  },
  {
    path: '/report',
    name: 'Report',
    component: Report,
    meta: { adminAuth: false }
  },
  {
    path: '/blank',
    name: 'blank',
    component: Blank
    /* meta: { adminAuth: true } */
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page

  const publicPages = ['login', 'login-success', 'error-404']
  const authRequired = !publicPages.includes(to.name)
  const loggedIn = store.getters['user/isLoggedIn']

  /* Got token but page refresh or just come to the app */
  if (store.getters['user/token'] != null && from.name == null) {
    await store.dispatch('user/getData', store.getters['user/token'])
  }
  const role = store.getters['user/role']

  if (authRequired && !loggedIn) {
    return next('/')
  } else {
    if (to.meta.adminAuth) {
      if (role === 'administrator') next()
      else next('/404')
    } else {
      return next()
    }
  }
})

export default router
