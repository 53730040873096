/* eslint-disable */
export default {
  state: {},
  mutations: {},
  actions: {
    getEnvironments({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/locations/" + payload + "/environments")
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getEnvVariables({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/locations/" + payload.locationId + "/latest-data/environments/" + payload.environmentId + '/variables')
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getEnvData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/locations/" + payload.locationId + "/data/environments/" + payload.environmentId + '/variables/' + payload.variableId, { params: payload.params })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    /* getEnvironments({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/locations/" + payload + "/appliances")
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    }, */
  },
  getters: {}
};
