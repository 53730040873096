<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-md-12">
        <div v-for="(item, i) in valueE" :key="i + 'main'" class="mb-3">
          <div class="input-group mb-2">
            <span
              class="input-group-text bg-dark text-white"
              style="min-width: 50px"
            >
              <strong>{{ i + 1 }}</strong>
            </span>
            <span class="input-group-text d-none d-lg-flex
"
              >Environment Name</span
            >
            <span
              class="input-group-text form-control bg-white"
              style="justify-content: flex-start"
              >{{ item.name }}</span
            >
            <span class="input-group-text bg-danger p-0" v-if="editMode">
              <button
                class="btn btn-sm tp-btn btn-rounded text-white"
                @click="$emit('deleteAppliances', i)"
              >
                <i class="fas fa-times"></i>
              </button>
            </span>
            <span class="input-group-text bg-primary p-0" v-if="editMode">
              <button
                class="btn btn-sm tp-btn btn-rounded text-white"
                @click="edit(i)"
                ref="addDialogBtn"
                data-bs-toggle="modal"
                data-bs-target="#addDialog"
              >
                <i class="fas fa-edit" v-if="editMode"></i>
                <i class="fas fa-view" v-else></i>
              </button>
            </span>
          </div>
        </div>
        <div
          v-if="!valueE.length"
          class="text-center p-3 text-light form-control mb-3"
        >
          <em>No Environment Added</em>
        </div>
      </div>
      <div class="col-md-12 text-center" v-if="editMode && 4 > valueE.length">
        <button
          class="btn btn-outline-primary m-auto"
          @click="addNewDialog = true"
          ref="addDialogBtn"
          data-bs-toggle="modal"
          data-bs-target="#addDialog"
        >
          <i class="fas fa-plus me-2"></i>
          Add Environments
        </button>
      </div>
    </div>

    <!--Dialog-->
    <Dialog
      :id="'addDialog'"
      @close=";(addNewDialog = false), clearAfterAdd()"
      centered
      large
      title="Add Environment"
    >
      <template slot="content">
        <div class="row">
          <div class="col-md-12 px-3 pb-3">
            <label class="form-label font-w600"
              >Environment Name<span class="text-danger scale5 ms-2">
                *</span
              ></label
            >
            <input
              v-model="form.name"
              :disabled="!editMode"
              class="form-control px-2"
            />
          </div>
          <div class="col-md-12 pt-3">
            <label class="form-label font-w600">Variables</label>
          </div>
          <div
            class="col-md-12 pb-2"
            v-for="(item, i) in form.variables"
            :key="i"
          >
            <div class="p-3 border rounded" style="background-color: #eee">
              <h4>{{ item.name }}</h4>
              <div class="row">
                <div class="col-xl-3 col-lg-6 col-md-6 py-3">
                  <label class="form-label font-w600"
                    >Status<span class="text-danger scale5 ms-2">
                      *</span
                    ></label
                  >
                  <div class="pb-2">
                    <select
                      class="form-select form-control"
                      v-model="item.status"
                      :disabled="!editMode"
                    >
                      <option :value="false">Disable</option>
                      <option :value="true">Enable</option>
                    </select>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 py-3">
                  <label class="form-label font-w600">Data Type</label>
                  <div class="pb-2">
                    <select
                      class="form-control"
                      v-model="item.data_type"
                      :disabled="true"
                    >
                      <option
                        v-for="(list, index) in types"
                        :value="list"
                        :key="index"
                      >
                        {{ list }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="col-xl-2 col-lg-3 col-md-3 col-sm-6 pt-3 pb-3 py-3"
                  v-if="item.data_type == 'Decimal'"
                >
                  <label class="form-label font-w600">Decimal</label>
                  <input
                    type="number"
                    :disabled="true"
                    v-model="item.decimal"
                    placeholder=""
                    class="form-control"
                  />
                </div>
                <div class="col-xl-2 col-lg-3 col-md-3 py-3">
                  <label class="form-label font-w600">Visibility</label>
                  <div class="pb-2">
                    <select
                      class="form-control form-select"
                      v-model="item.visibility"
                      :disabled="!editMode"
                    >
                      <option :value="true">Show</option>
                      <option :value="false">Hide</option>
                    </select>
                  </div>
                </div>

                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6 pt-3 pb-3 py-3">
                  <label class="form-label font-w600">Unit</label>
                  <input
                    type="text"
                    v-model="item.unit"
                    :disabled="true"
                    placeholder=""
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row" v-if="item.data_type !== 'Text'">
                <div class="col-12 py-3 d-none d-lg-block">
                  <label class="form-label font-w600">Ranges</label>
                  <div
                    class="input-group mb-2"
                    v-if="
                      ranges.length && item.data_type === 'Decimal' && editMode
                    "
                  >
                    <span
                      class="input-group-text bg-primary text-white"
                      style="max-width: 50px"
                      ><strong>ADD</strong></span
                    >
                    <span class="input-group-text">Min</span>
                    <input
                      type="number"
                      class="form-control"
                      v-model="ranges[i].min"
                    />
                    <span class="input-group-text">Max</span>
                    <input
                      type="number"
                      class="form-control"
                      v-model="ranges[i].max"
                    />
                    <span class="input-group-text">Status</span>
                    <select
                      class="form-select form-control"
                      v-model="ranges[i].status"
                    >
                      <option
                        :selected="ranges[i].status == null ? true : false"
                        :value="null"
                      >
                        Choose...
                      </option>
                      <option
                        v-for="(list, index) in statusRange"
                        :value="list"
                        :key="index"
                      >
                        {{ list }}
                      </option>
                    </select>
                    <span class="input-group-text p-0 bg-primary">
                      <button
                        class="btn btn-sm tp-btn btn-rounded text-white"
                        @click="addRange(i)"
                        :disabled="
                          !ranges[i].min || !ranges[i].max || !ranges[i].status
                        "
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </span>
                  </div>
                  <div
                    class="input-group mb-2"
                    v-if="
                      ranges.length && item.data_type === 'Boolean' && editMode
                    "
                  >
                    <span
                      class="input-group-text bg-primary text-white"
                      style="max-width: 50px"
                      ><strong>ADD</strong></span
                    >
                    <span class="input-group-text">Value</span>
                    <select
                      class="form-select form-control"
                      v-model="ranges[i].value"
                    >
                      <option
                        :selected="ranges[i].status == null ? true : false"
                        :value="null"
                      >
                        Choose...
                      </option>
                      <option value="false">False</option>
                      <option value="true">True</option>
                    </select>
                    <span class="input-group-text">Status</span>
                    <select
                      class="form-select form-control"
                      v-model="ranges[i].status"
                    >
                      <option
                        :selected="ranges[i].status == null ? true : false"
                        :value="null"
                      >
                        Choose...
                      </option>
                      <option
                        v-for="(list, index) in statusRange"
                        :value="list"
                        :key="index"
                      >
                        {{ list }}
                      </option>
                    </select>
                    <span class="input-group-text p-0 bg-primary">
                      <button
                        class="btn btn-sm tp-btn btn-rounded text-white"
                        @click="addRange(i)"
                        :disabled="!ranges[i].value || !ranges[i].status"
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </span>
                  </div>

                  <template v-if="item.data_type === 'Decimal'">
                    <div
                      class="input-group mb-2"
                      :class="
                        !rangeList.min || !rangeList.max || !rangeList.status
                          ? 'input-danger-o'
                          : ''
                      "
                      v-for="(rangeList, index) in item.ranges"
                      :key="i + index + 'rg2'"
                    >
                      <span
                        class="input-group-text bg-dark text-white"
                        style="min-width: 50px"
                        ><strong>{{ index + 1 }}</strong></span
                      >
                      <span class="input-group-text">Min</span>
                      <input
                        type="number"
                        :disabled="!editMode"
                        class="form-control"
                        v-model="item.ranges[index].min"
                      />
                      <span class="input-group-text">Max</span>
                      <input
                        type="number"
                        :disabled="!editMode"
                        class="form-control"
                        v-model="rangeList.max"
                      />
                      <span class="input-group-text">Status</span>
                      <select
                        :disabled="!editMode"
                        class="form-select form-control"
                        v-model="rangeList.status"
                      >
                        <option
                          :selected="rangeList.status == null ? true : false"
                          :value="null"
                        >
                          Choose...
                        </option>
                        <option
                          v-for="(list, index) in statusRange"
                          :value="list"
                          :key="index"
                        >
                          {{ list }}
                        </option>
                      </select>
                      <span
                        class="input-group-text bg-danger p-0"
                        v-if="editMode"
                      >
                        <button
                          class="btn btn-sm tp-btn btn-rounded text-white"
                          @click="deleteApplianceRange(i, index)"
                        >
                          <i class="fas fa-times"></i>
                        </button>
                      </span>
                    </div>
                  </template>
                  <template v-else-if="item.data_type === 'Boolean'">
                    <div
                      class="input-group mb-2"
                      :class="
                        !rangeList.value || !rangeList.status
                          ? 'input-danger-o'
                          : ''
                      "
                      v-for="(rangeList, index) in item.ranges"
                      :key="index + 'lg'"
                    >
                      <span class="input-group-text">Value</span>
                      <select
                        class="form-select form-control"
                        v-model="rangeList.value"
                        :disabled="!editMode"
                      >
                        <option
                          :selected="rangeList.status == null ? true : false"
                          :value="null"
                        >
                          Choose...
                        </option>
                        <option value="false">False</option>
                        <option value="true">True</option>
                      </select>
                      <span class="input-group-text">Status</span>
                      <select
                        :disabled="!editMode"
                        class="form-select form-control"
                        v-model="rangeList.status"
                      >
                        <option
                          :selected="rangeList.status == null ? true : false"
                          :value="null"
                        >
                          Choose...
                        </option>
                        <option
                          v-for="(list, index) in statusRange"
                          :value="list"
                          :key="index"
                        >
                          {{ list }}
                        </option>
                      </select>
                      <span
                        class="input-group-text bg-danger p-0"
                        v-if="editMode"
                      >
                        <button
                          class="btn btn-sm tp-btn btn-rounded text-white"
                          @click="deleteApplianceRange(i, index)"
                        >
                          <i class="fas fa-times"></i>
                        </button>
                      </span>
                    </div>
                  </template>
                  <div
                    v-if="!item.ranges.length"
                    class="text-center p-3 text-light form-control"
                  >
                    <em>No Range Added</em>
                  </div>
                </div>
                <div class="col-12 py-3 d-block d-lg-none">
                  <label class="form-label font-w600">Ranges</label>
                  <div
                    class="input-group mb-1"
                    v-if="
                      ranges.length && item.data_type === 'Decimal' && editMode
                    "
                  >
                    <span
                      class="input-group-text bg-primary text-white"
                      style="max-width: 50px"
                      ><strong>ADD</strong></span
                    >
                    <span class="input-group-text">Min</span>
                    <input
                      type="number"
                      class="form-control"
                      v-model="ranges[i].min"
                    />
                    <span class="input-group-text">Max</span>
                    <input
                      type="number"
                      class="form-control"
                      v-model="ranges[i].max"
                    />
                  </div>
                  <div
                    class="input-group mb-3"
                    v-if="
                      ranges.length && item.data_type === 'Decimal' && editMode
                    "
                  >
                    <span class="input-group-text">Status</span>
                    <select
                      class="form-select form-control"
                      v-model="ranges[i].status"
                    >
                      <option
                        :selected="ranges[i].status == null ? true : false"
                        :value="null"
                      >
                        Choose...
                      </option>
                      <option
                        v-for="(list, index) in statusRange"
                        :value="list"
                        :key="index"
                      >
                        {{ list }}
                      </option>
                    </select>
                    <span class="input-group-text p-0 bg-primary">
                      <button
                        class="btn btn-sm tp-btn btn-rounded text-white"
                        @click="addRange(i)"
                        :disabled="
                          !ranges[i].min || !ranges[i].max || !ranges[i].status
                        "
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </span>
                  </div>
                  <div
                    class="input-group mb-1"
                    v-if="
                      ranges.length && item.data_type === 'Boolean' && editMode
                    "
                  >
                    <span
                      class="input-group-text bg-primary text-white"
                      style="max-width: 50px"
                      ><strong>ADD</strong></span
                    >
                    <span class="input-group-text">Value</span>
                    <select
                      class="form-select form-control"
                      v-model="ranges[i].value"
                    >
                      <option
                        :selected="ranges[i].status == null ? true : false"
                        :value="null"
                      >
                        Choose...
                      </option>
                      <option value="false">False</option>
                      <option value="true">True</option>
                    </select>
                  </div>
                  <div
                    class="input-group mb-3"
                    v-if="
                      ranges.length && item.data_type === 'Boolean' && editMode
                    "
                  >
                    <span class="input-group-text">Status</span>
                    <select
                      class="form-select form-control"
                      v-model="ranges[i].status"
                    >
                      <option
                        :selected="ranges[i].status == null ? true : false"
                        :value="null"
                      >
                        Choose...
                      </option>
                      <option
                        v-for="(list, index) in statusRange"
                        :value="list"
                        :key="index"
                      >
                        {{ list }}
                      </option>
                    </select>
                    <span class="input-group-text p-0 bg-primary">
                      <button
                        class="btn btn-sm tp-btn btn-rounded text-white"
                        @click="addRange(i)"
                        :disabled="!ranges[i].value || !ranges[i].status"
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </span>
                  </div>

                  <template v-if="item.data_type === 'Decimal'">
                    <template v-for="(rangeList, index) in item.ranges">
                      <div
                        class="input-group mb-1"
                        :key="0 + i + index + 'rgx'"
                        :class="
                          !rangeList.min || !rangeList.max || !rangeList.status
                            ? 'input-danger-o'
                            : ''
                        "
                      >
                        <span
                          class="input-group-text bg-dark text-white"
                          style="min-width: 50px"
                          ><strong>{{ index + 1 }}</strong></span
                        >
                        <span class="input-group-text">Min</span>
                        <input
                          type="number"
                          :disabled="!editMode"
                          class="form-control"
                          v-model="item.ranges[index].min"
                        />
                        <span class="input-group-text">Max</span>
                        <input
                          type="number"
                          :disabled="!editMode"
                          class="form-control"
                          v-model="rangeList.max"
                        />
                      </div>
                      <div
                        class="input-group mb-3"
                        :key="0 + i + index + 'rgz'"
                        :class="
                          !rangeList.min || !rangeList.max || !rangeList.status
                            ? 'input-danger-o'
                            : ''
                        "
                      >
                        <span class="input-group-text">Status</span>
                        <select
                          :disabled="!editMode"
                          class="form-select form-control"
                          v-model="rangeList.status"
                        >
                          <option
                            :selected="rangeList.status == null ? true : false"
                            :value="null"
                          >
                            Choose...
                          </option>
                          <option
                            v-for="(list, index) in statusRange"
                            :value="list"
                            :key="index"
                          >
                            {{ list }}
                          </option>
                        </select>
                        <span
                          class="input-group-text bg-danger p-0"
                          v-if="editMode"
                        >
                          <button
                            class="btn btn-sm tp-btn btn-rounded text-white"
                            @click="deleteApplianceRange(i, index)"
                          >
                            <i class="fas fa-times"></i>
                          </button>
                        </span>
                      </div>
                    </template>
                  </template>
                  <template
                    v-else-if="item.data_type === 'Boolean'"
                    v-for="(rangeList, index) in item.ranges"
                  >
                    <div
                      class="input-group mb-1"
                      :class="
                        !rangeList.value || !rangeList.status
                          ? 'input-danger-o'
                          : ''
                      "
                      :key="index + 'lgx'"
                    >
                      <span
                        class="input-group-text bg-dark text-white"
                        style="min-width: 50px"
                        ><strong>{{ index + 1 }}</strong></span
                      >
                      <span class="input-group-text">Value</span>
                      <select
                        class="form-select form-control"
                        v-model="rangeList.value"
                        :disabled="!editMode"
                      >
                        <option
                          :selected="rangeList.status == null ? true : false"
                          :value="null"
                        >
                          Choose...
                        </option>
                        <option value="false">False</option>
                        <option value="true">True</option>
                      </select>
                    </div>
                    <div
                      class="input-group mb-3"
                      :key="index + 'lgz'"
                      :class="
                        !rangeList.value || !rangeList.status
                          ? 'input-danger-o'
                          : ''
                      "
                    >
                      <span class="input-group-text">Status</span>
                      <select
                        :disabled="!editMode"
                        class="form-select form-control"
                        v-model="rangeList.status"
                      >
                        <option
                          :selected="rangeList.status == null ? true : false"
                          :value="null"
                        >
                          Choose...
                        </option>
                        <option
                          v-for="(list, index) in statusRange"
                          :value="list"
                          :key="index"
                        >
                          {{ list }}
                        </option>
                      </select>
                      <span
                        class="input-group-text bg-danger p-0"
                        v-if="editMode"
                      >
                        <button
                          class="btn btn-sm tp-btn btn-rounded text-white"
                          @click="deleteApplianceRange(i, index)"
                        >
                          <i class="fas fa-times"></i>
                        </button>
                      </span>
                    </div>
                  </template>
                  <div
                    v-if="!item.ranges.length"
                    class="text-center p-3 text-light form-control"
                  >
                    <em>No Range Added</em>
                  </div>
                </div>
              </div>
              <div class="row" v-if="item.data_type == 'Decimal'">
                <div class="col-xl-12 py-3">
                  <label class="form-label font-w600"
                    >Threshold Notification</label
                  >
                  <div class="input-group me-2">
                    <span class="input-group-text d-flex align-items-center">
                      <input
                        type="checkbox"
                        class="form-check-input mt-0 me-2"
                        :disabled="!editMode"
                        v-model="item.thresholdNotification.trigMin"
                      />
                      <span class="mt-1">Min</span>
                    </span>
                    <input
                      type="number"
                      v-model="item.thresholdNotification.min"
                      :disabled="!editMode"
                      placeholder=""
                      class="form-control px-2"
                    />
                    <span class="input-group-text d-flex align-items-center">
                      <input
                        type="checkbox"
                        class="form-check-input mt-0 me-2"
                        :disabled="!editMode"
                        v-model="item.thresholdNotification.trigMax"
                      />
                      <span class="mt-1">Max</span>
                    </span>
                    <input
                      type="number"
                      v-model="item.thresholdNotification.max"
                      :disabled="!editMode"
                      placeholder=""
                      class="form-control px-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="footer">
        <a
          class="btn btn-danger btn-sm light"
          ref="closeDialogBtn"
          data-bs-toggle="modal"
          @click="clearAfterAdd()"
          data-bs-target="#addDialog"
          >Cancel</a
        >
        <a
          class="btn btn-primary btn-sm"
          :disabled="!this.form.name"
          @click="addAppliance"
          v-if="this.selected == null && editMode"
          >Add Environment</a
        >
        <a
          class="btn btn-primary btn-sm"
          :disabled="!this.form.name"
          @click="updateAppliance"
          v-if="this.selected !== null && editMode"
          >Update Environment</a
        >
      </template>
    </Dialog>
  </div>
</template>
<script>
import Dialog from '@/components/Templates/Dialog'
export default {
  props: ['valueE', 'editMode'],
  components: {
    Dialog
  },
  data () {
    return {
      editmode: true,
      addNewDialog: false,
      editDialog: false,
      selected: null,
      statusRange: ['Normal', 'Warning', 'Alert'],
      types: ['Number', 'Decimal', 'Boolean', 'Text'],
      ranges: [],
      form: {
        name: '',
        icon: '',
        variables: [
          {
            name: 'Humidity',
            data_type: 'Decimal',
            decimal: 1,
            status: true,
            visibility: true,
            unit: null,
            ranges: [],
            thresholdNotification: {
              trigMin: false,
              trigMax: false,
              min: 0,
              max: 0
            }
          },
          {
            name: 'Temperature',
            data_type: 'Decimal',
            decimal: 1,
            status: true,
            visibility: true,
            unit: '°C',
            ranges: [],
            thresholdNotification: {
              trigMin: false,
              trigMax: false,
              min: 0,
              max: 0
            }
          }
        ]
      },
      // templates data

      daysDefault: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      }
    }
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    },
    isMobileView () {
      return this.$store.getters['global/mobileView']
    }
  },
  created () {
    this.ranges = []
    this.populateRanges()
  },
  watch: {},
  methods: {
    clearAfterAdd () {
      this.days = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      }
      this.form = {
        name: '',
        icon: '',
        variables: [
          {
            name: 'Humidity',
            data_type: 'Decimal',
            decimal: 1,
            status: true,
            visibility: true,
            unit: null,
            ranges: [],
            thresholdNotification: {
              trigMin: false,
              trigMax: false,
              min: 0,
              max: 0
            }
          },
          {
            name: 'Temperature',
            data_type: 'Decimal',
            decimal: 1,
            status: true,
            visibility: true,
            unit: '°C',
            ranges: [],
            thresholdNotification: {
              trigMin: false,
              trigMax: false,
              min: 0,
              max: 0
            }
          }
        ]
      }
      this.ranges = []
      this.populateRanges()
    },
    populateRanges () {
      this.form.variables.forEach(el => {
        if (el.data_type === 'Decimal') {
          this.ranges.push({ min: 0, max: 0, status: null })
        } else if (el.data_type === 'Boolean') {
          this.ranges.push({ value: '', status: null })
        }
      })
    },
    deleteAppliance (i) {
      this.$emit('deleteEnvironment', i)
    },
    addRange (i) {
      // this.form.variables[i].ranges.push(payload)
      // console.log(this.form.variables[i].ranges)
      if (this.form.variables[i].data_type === 'Decimal') {
        const payload = {
          min: this.ranges[i].min,
          max: this.ranges[i].max,
          status: this.ranges[i].status
        }
        this.form.variables[i].ranges.push(payload)
        this.ranges[i].min = 0
        this.ranges[i].max = 0
        this.ranges[i].status = null
      } else if (this.form.variables[i].data_type === 'Boolean') {
        const payload = {
          value: this.ranges[i].value,
          status: this.ranges[i].status
        }
        this.form.variables[i].ranges.push(payload)
        this.ranges[i].value = 0
        this.ranges[i].status = null
      }
    },
    /*eslint-disable */
    addAppliance() {
      if (this.form.name) {
        const payload = this.form
        this.$emit('addEnvironments', payload)
        this.$refs.closeDialogBtn.click()
        this.clearAfterAdd()
      }
    },
    updateAppliance() {
      if (this.form.name) {
        const payload = {
          index: this.selected,
          data: this.form
        }

        this.$emit('updateEnvironment', payload)
        this.$refs.closeDialogBtn.click()
        this.clearAfterAdd()
        this.selected = null
      }
    },
    edit(i) {
      this.selected = i
      this.addNewDialog = true
      if (this.valueE[i].id) this.form.id = this.valueE[i].id
      this.form.name = this.valueE[i].name
      this.form.icon = this.valueE[i].icon
      this.form.variables = this.valueE[i].variables
    },
    deleteApplianceRange(i, index) {
      this.form.variables[i].ranges.splice(index, 1)
    }
  }
}
</script>

<style></style>
